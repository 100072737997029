import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay'; // 导入 Autoplay 模块的样式
import { Pagination, Navigation, Scrollbar, Autoplay } from 'swiper/modules';
import './screen.scss';

const Carousel = ({images=[
  { src: '/children/swiper1.png', alt: 'Overlay Style' },
  { src: '/children/swiper2.png', alt: 'Shadow Style' },
  { src: '/children/swiper3.png', alt: 'Celay+S Style' },
  { src: '/children/swiper4.png', alt: 'Overlay Style' },
  { src: '/children/swiper5.png', alt: 'Shadow Style' },
  { src: '/children/swiper6.png', alt: 'Celay+S Style' },
]}) => {

  return (
    <div className="screen-swiper">
      <Swiper
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        // centeredSlides
        modules={[Navigation, Pagination, Scrollbar, Autoplay]} // 添加 Autoplay 模块
        spaceBetween={20} // 调整幻灯片间距
        slidesPerView={3} // 同时展示3个幻灯片
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img src={image.src} alt={image.alt} className="carousel-image" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;