import { Box, Button } from '@mui/material';
import { useNavigate  } from 'react-router-dom';
import './sheepfarm.scss'
const About = () => {
  const sheepFarm = window.config.sheepFarm || {}
  const navigate = useNavigate();
  const routerHandler = () => {
    navigate('/contactus');
  }
  return (
    <div id='sheepfarm'>
      <div className='sheepfarm-image'>
        <img src='/screen/homesheepfarm.png' alt='screnn-home' />
        <div className='sheepfarm-image-position'>
          <Box className='sheepfarm-title' sx={{
            fontSize:{
              lg: '36px',
              xl: '36px'
            }
          }}>{sheepFarm.title}</Box>
          <Box  className='sheepfarm-text' sx={{
            padding:'0px 30%',
          }}>{sheepFarm.subTitle}</Box>
          <Button className='sheepfarm-button cusor-point' sx={{
            color:'#fff',
            padding: {
              md: "10px 150px",
              xs: "8px 16px",
            }
          }} onClick={()=>{routerHandler()}}>申请体验</Button>
        </div>
      </div>
      {
        sheepFarm.content&& sheepFarm.content.map((i,index)=>{
          return <div className='sheepfarm-show' key={index}>
          <div className='yfjs-title-white'>{i.title}</div>
          <div className='yfjs-text-white'>{i.text}</div>
        </div>
        })
      }
    </div>

  );
};

export default About;
