import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation,Scrollbar } from 'swiper/modules';
import 'swiper/swiper-bundle.css';


const Carousel = ({images=[
  { src: 'children/join2.png', alt: '1' },
  { src: 'children/join3.png', alt: '2' },
  { src: 'children/join2.png', alt: '3' },
]}) => {

  return (
    <div className="contact-swiper">
      <Swiper
       autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      loop={true} // 启用循环播放
      centeredSlides
      modules={[Navigation, Pagination, Scrollbar]}
      spaceBetween={50}
      slidesPerView={1}
      navigation // 对应 Navigation
      pagination={{ clickable: true }} // 对应 Pagination
      scrollbar={{ draggable: true }} // 对应 Scrollbar
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img src={image.src} alt={image.alt} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;