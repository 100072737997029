import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation,Scrollbar,Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';


const Carousel = () => {

  const images = [
    { src: '/sheep/1.jpg', alt: 'Overlay Style' },
    { src: '/sheep/2.jpg', alt: 'Shadow Style' },
    { src: '/sheep/3.jpg', alt: 'Celay+S Style' },
    { src: '/sheep/4.jpg', alt: 'Shadow Style' },
    { src: '/sheep/5.jpg', alt: 'Celay+S Style' },
  ];

  return (
    <div className="contact-swiper">
      <Swiper
       autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      loop={true} // 启用循环播放
      centeredSlides
      modules={[Navigation, Pagination, Scrollbar, Autoplay]} // 添加 Autoplay 模块
      spaceBetween={50}
      slidesPerView={1}
      navigation // 对应 Navigation
      pagination={{ clickable: true }} // 对应 Pagination
      scrollbar={{ draggable: true }} // 对应 Scrollbar
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img src={image.src} alt={image.alt} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;