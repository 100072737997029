import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './fireFarm.scss'
const About = () => {
  const fireFarm =  window.config.fireFarm || {}
  const navigate = useNavigate();
  const routerHandler = () => {
    navigate('/contactus');
  }
  return (
    <div id='fireFarm'>
      <div className='sheepfarm-image'>
        <img src='/screen/homefirefarm.png' alt='screnn-home' />
        <div className='sheepfarm-image-position'>
          <Box className='sheepfarm-title' sx={{
            fontSize: {
              lg: '36px',
              xl: '36px'
            }
          }}>{fireFarm.title}</Box>
          <Box className='sheepfarm-text' sx={{
            padding: '0px 10%',
          }}>{fireFarm.subTitle}</Box>
          <Button className='sheepfarm-button cusor-point' sx={{
            color: '#fff',
            padding: {
              md: "10px 150px",
              xs: "8px 16px",
            }
          }} onClick={() => { routerHandler() }}>申请体验</Button>
        </div>
      </div>
      <div className='yfjs-title-white-16 sheepfarm-contaner'>
        {fireFarm.contentTitle}
      </div>
      {
        fireFarm.content&&fireFarm.content.map((i,index)=>{
          return <div className='sheepfarm-show' key={index}>
          <div className='yfjs-title-white-16'>
            {i.title}
          </div>
          <div>{i.text}</div>
        </div>
        })
      }
    </div>

  );
};

export default About;
