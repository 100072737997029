// import { useState, useEffect } from 'react';
// import axios from 'axios';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import '../scss/about.scss'
import { Grid } from '@mui/material';
import NorthEast from '@mui/icons-material/NorthEast';
import MySwiperComponent from './com/swiper';
const About = (): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();
  const routerHandler = () => {
    navigate('/contact');
  }
  return (
    <div id='about'>
      <Box
        sx={{
          paddingTop: 5,
          paddingBottom: 12,
          paddingX: 2,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Box marginBottom={4}>
          <Typography
            variant='h5'
            align='center'
            color={theme.palette.text.primary}
            fontWeight={700}
            marginTop={theme.spacing(1)}
            gutterBottom
            sx={{
              textTransform: 'uppercase',
              marginBottom: 2,
            }}
          >
            关于我们
          </Typography>
        </Box>
        <Container >
          <Box
            className='container'
            // padding={8}
            width={1}
            height={1}
            bgcolor={'#262626'}

            sx={{
              '&:hover': {
                bgcolor: theme.palette.background.paper,
                color: theme.palette.common.white,
              },
            }}
          >
            <Grid container alignItems={'center'} justifyContent={'center'}>
              <Grid>
                <div className='container-top'>
                  上海翼方键数科技有限公司成立于2021年8月，汇聚了来自IBM、GE、富士康等国际一流团队的核心成员。我们专注于将云计算、移动互联网、物联网、大数据、人工智能等新技术与传统工业深度融合，致力于成为传统工业企业数字化转型的最佳合作伙伴。
                </div>
              </Grid>
              <Grid container alignItems={'flex-end'} justifyContent={'space-Between'} className='phone-style-about'>
                <Grid item md={6} sm={12} alignItems={'center'} justifyContent={'center'}>
                  <div className='container-right'>
                    <img src='about2.png' alt='about2' />
                    <div className='container-right-title'>
                      公司与应急管理部门某省消防研究所合作，推动智慧安全战略；与普华永道、民航研究所等建立战略合作关系，共同推进数字化转型和智能运维；与新疆某羊场合作，推出数字化畜牧智能管理平台。
                    </div>
                    <div className='container-right-text'>
                      我们的低代码开发平台基于丰富的行业项目经验，提供灵活的组件库和模板。该平台不仅加速了应用开发，还结合行业需求优化解决方案，助力企业实现高效数字化转型和智能化升级，提升业务运营效率。
                    </div>
                    <div className='container-right-button cusor-point' onClick={() => routerHandler()}>
                      了解更多
                      <NorthEast style={{ marginLeft: '10px' }} />
                    </div>
                  </div>
                </Grid>
                <Grid item md={5} sm={12} alignItems={'center'} justifyContent={'center'} className='container-center'>
                  <div className='container-left'>
                  <MySwiperComponent />
                </div>
                </Grid>
              </Grid>
            </Grid>


          </Box>






        </Container>
      </Box>
    </div>
  );
};

export default About;
