// import { useState, useEffect } from 'react';
// import axios from 'axios';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import '../scss/contact.scss'

const Contact = (): JSX.Element => {
  const theme = useTheme();
  return (
    <div id='contactus'>
      <Box
      
        sx={{
          paddingTop: 5,
          paddingBottom: 10,
          paddingX: 2,
          backgroundColor: theme.palette.background.paper,
        }}
        className='contact-out'
      >
        <Box marginBottom={4}>
          <Typography
            variant='h5'
            align='center'
            fontWeight={700}
            marginTop={theme.spacing(1)}
            gutterBottom
            sx={{
              color: theme.palette.text.primary,
              textTransform: 'uppercase',
            }}
          >
            联系我们
          </Typography>
        </Box>
        <Container className='container-contact'>
          <div className='container-top'>
            <div className='container-top-left'>
              <span style={{flexShrink:0,fontSize:'20px'}}>电话</span>
              <span style={{ marginLeft:'20px' }}>177-1705-0588</span>
            </div>
            <div className='container-top-right'>
              <span>关注我们</span>
              <img src="contact.png" alt="contact" />
            </div>
          </div>
        </Container>
      </Box>
    </div>
  );
};

export default Contact;
