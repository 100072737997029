// import { useState, useEffect } from 'react';
// import axios from 'axios';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import '../scss/partner.scss'
import { styled } from '@mui/material/styles';
import { Grid,Paper  } from '@mui/material';

const About = (): JSX.Element => {
  const theme = useTheme();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#F5F5F5' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  
  return (
    <div id='partner'>
      <Box
        sx={{
          paddingTop: 5,
          paddingBottom: 12,
          paddingX: 2,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Box marginBottom={4}>
          <Typography
            variant='h5'
            align='center'
            color={theme.palette.text.primary}
            fontWeight={700}
            marginTop={theme.spacing(1)}
            gutterBottom
            sx={{
              textTransform: 'uppercase',
              marginBottom: 2,
            }}
          >
            合作伙伴
          </Typography>
        </Box>
        <Container className='container'>
          <Grid container spacing={2} className='container-center'>
            <Grid item xs={2}>
              <Item className='container-image-item'>
                 <img src='/partner1.png' alt='1'/>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item className='container-image-item'>
                 <img src='/partner2.png' alt='1'/>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item className='container-image-item'>
                 <img src='/partner3.png' alt='1'/>
              </Item>
            </Grid>
            <Grid item xs={2}>
            <Item className='container-image-item'>
                 <img src='/partner4.png' alt='1'/>
              </Item>
            </Grid>
            {/* <Grid item xs={2}>
              <Item>xs=8</Item>
            </Grid>
            <Grid item xs={2}>
              <Item>xs=4</Item>
            </Grid>
            <Grid item xs={2}>
              <Item>xs=4</Item>
            </Grid>
            <Grid item xs={2}>
              <Item>xs=8</Item>
            </Grid>
            <Grid item xs={2}>
              <Item>xs=4</Item>
            </Grid>
            <Grid item xs={2}>
              <Item>xs=8</Item>
            </Grid>
            <Grid item xs={2}>
              <Item>xs=4</Item>
            </Grid>
            <Grid item xs={2}>
              <Item>xs=8</Item>
            </Grid> */}
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default About;
