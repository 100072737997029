
import { useState } from 'react';

import Modal from './modal'
import './join.scss'
import { Box, Grid  } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
const About = () => {
  const join =  window.config.join || {}
  const [open, setOpen] = useState(-1);
  const clickHandler = (res)=>{
    setOpen(res)
  }
  return (
    <div id='join'>
      <div className='join-image'>
        <img src='/children/join1.png' alt='contact-home' />
        <Box className='join-imahe-position' sx={{
            left: {
              xs: '0 !important',
              xl: '0 !important'
            },
            right: {
              xs: '0 !important',
              xl: '0 !important'
            }
        }}>
          <p className='yfjs-title-white'>{join.title}</p>
          <p className='yfjs-text-white'>{join.subTitle}</p>
        </Box>
      </div>
      <div className='join-show'>
        <p className='yfjs-title-white'>招聘岗位</p>
        <div className='join-show-display'>
          <Grid container spacing={4}>
            {
              join.content&&join.content.map((item,index)=>{
                return   <Grid item md={6} key={index}>
                <div className='join-show-display-item'>
                <div className='join-show-title'>  <span className='yfjs-title-bold'>{item.title}</span>{item.location&&<span><LocationOnIcon /></span>}<span>{item.location}</span></div>
                <ui>
                  {
                    item.text&&item.text.map((i,ide)=>{
                      return  <li  key={ide}>{ i }</li>
                    })
                  }
                </ui>
                <p className='yfjs-text cusor-point' onClick={()=>clickHandler(index)}>更多内容➡</p>
                <div className='join-button cusor-point'  onClick={()=>clickHandler(index)}>
                  立即申请
                </div>
              </div>
              <Modal key={index}  open={open===index?true:false} setOpen={(index)=>setOpen(index)} modal={item.modal}/>
            </Grid>
              })
            }
          </Grid>
          
          
          

        </div>
      </div>

     

    </div>
  );
};

export default About;
