import { Box, Grid } from '@mui/material';
import './design.scss'
const About = () => {
  const design = window.config.design || {}
  return (
    <div id='design'>
      <div className='sheep-image'>
        <img src='/screen/homedesign.png' alt='screnn-home' />
        <Box className='sheep-image-position'
        >
          <p className='sheep-image-position-p'>{design.title}</p>
          <div className='sheep-image-padding'>
            {design.subTitle}
          </div>
        </Box>
      </div>
      <div className='sheep-show-top'>{design.contentTitle}</div>
      <div className='sheep-show'>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={12} md={5}>
            <div className='sheep-show-left'>
              {
                design.content&&design.content.map((i,index)=>{
                  return <div className={index % 2 === 0?'sheep-show-left-top':'sheep-show-left-bottom'} key={index}>
                  <div className='sheep-show-title'>
                    {i.title}
                  </div>
                  <div className='sheep-show-text'>
                    <ul>
                      {i.option.map((j,jindex)=>{
                        return <li key={jindex}>{j}</li>
                      })}
                    </ul>
                  </div>
  
                </div>
                })
              }
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className='sheep-show-right'>
            {
                design.contentText&&design.contentText.map((i,index)=>{
                  return <div>
                  <span className='yfjs-title-white-14'>{i.title}</span>{i.text}
                </div>
                })
              }
            </div>
          </Grid>
        </Grid>
      </div>
      <div className='sheep-show-bottom'>
        <p>{design.bottomTitle}</p>
        <div>
          <img src={design.bottomImg1||'/children/design1.png'} alt='1'/>
        </div>
      </div>
      <div className='sheep-show-footer'>
           <img src={design.bottomImg2||'/children/design2.png'} alt='2'/>
      </div>

    </div>

  );
};

export default About;
