import backgroundImage from '../pages/yfjs-nav.png';
import '../scss/image.scss'

const CustomButton = (): JSX.Element => {
  const imageStyle = {
    width: '100%', // 设置图片宽度为父元素宽度的100%
    // height: window.screen.width > 400 ? '700px' : '300px', // 让高度自动适应宽度变化，保持比例
    'object-fit': 'cover',
    // 可以添加其他样式，例如 margin, padding, boxShadow 等
  };

  return (
    <div className='home-image-position'>
      <img className='backgroundImage' src={backgroundImage} alt="YFJS Website Background" style={imageStyle} />
      <div className='home-image-text'>
        <div className='font-title'>依托工业互联网领域核心技术和工业数字化转型的丰富项目沉淀</div>
        <div className='yfjs-text-color'>
          <p>您的数字化产品  我们的技术专长 共筑未来！</p>
          <p style={{opacity:'0.5'}}>Your product.Our mastery.Lasting partnerships.</p>
        </div>
        <div className='yfjs-text-white'>公司致力于云、移、物、大、智、网等新技术与工业的融合创新，成为传统工业企业数字化转型合伙人</div>
      </div>
    </div>
  );
};

export default CustomButton;
