import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import '../scss/contactfooter.scss'

const Contact = (): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();
  const routerHandler = (key:any) => {
    navigate(key);
  }
  return (
    <div id='contactfooter'>
      <Box
        sx={{
          paddingTop: 5,
          paddingBottom: 5,
          paddingX: 2,
          backgroundColor: theme.palette.background.paper,
          borderTop:'1px solid #454545',
          borderBottom:'1px solid #454545'
        }}
        className='contact-out'
      >
        <Container className='container-contact'>
          <div className='container-bottom' >
            <Grid container spacing={2} sx={{
               padding: { xs:4 }
            }}>
              <Grid item md={4}>
                <div className='container-left'>
                  <img src="/yfjs-logo-w.png" alt="yfjs-logo-w.png" />

                </div>
              </Grid>
              <Grid item md={4} className='footer-gird-margin'>
                <div className='container-center'>
                  <div className='container-center-title'>
                    更多信息
                  </div>
                  <div className='container-center-text'>
                    <p className='cusor-point' onClick={()=>routerHandler('/datasit')}>产品服务</p>
                    <p className='cusor-point' onClick={()=>routerHandler('/sheefarm')}>客户案例</p>
                    <p className='cusor-point' onClick={()=>routerHandler('/sheep')}>解决方案</p>
                    <p className='cusor-point' onClick={()=>routerHandler('/contact')}>关于我们</p>
                    <p className='cusor-point' onClick={()=>routerHandler('/join')}>人才招聘</p>
                  </div>
                </div>
              </Grid>
              <Grid item md={4}  className='footer-gird-margin'>
                <div className='container-right'>
                  <div className='container-center-title'>
                    联系方式
                  </div>
                  <div className='container-right-text'>
                    <img src="/phone.png" alt="phone.png" />
                    <span>17717050588</span>
                  </div>
                  <div className='container-right-text'>
                    <img src="/email.png" alt="email.png" />
                    <span>contact@yfjstech.com</span>
                  </div>
                  <div className='container-right-text'>
                    <img src="/home.png" alt="home.png" />
                    <span>上海市金山区张堰镇松金公路2525号(张堰经济园区)</span>
                  </div>
                </div>
              </Grid>

            </Grid>


          </div>


        </Container>
      </Box>
    </div>
  );
};

export default Contact;
