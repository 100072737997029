import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

interface Props {
  href?: string;
  text: string;
  classname?:string;
  open?:boolean;
  hasopen?:boolean;
  onClick?: () => void;
}

const CustomButton = ({ href, text, classname,open, hasopen, onClick}: Props): JSX.Element => {
  const theme = useTheme();
  const onClickHandler = () => {
    onClick && onClick()
  }
  return (
    <Button
      className={classname}
      component='div'
      color='primary'
      href={href}
      variant='text'
      onClick={onClickHandler}
      sx={{
        fontSize:'18px',
        color: theme.palette.text.primary,
        textTransform: 'uppercase',
        marginX: 1.5,
        marginLeft: '15px',
        display: 'flex',
        width: '100%',
        justifyContent:'flex-start',
        md: {
          '&:active': {
            color: theme.palette.primary.main,
          },
          '&:hover': {
            color: theme.palette.primary.main,
          },
        }
      
      }}
    >
      {text}
      {
        hasopen ? open ? <ExpandLess style={{
          marginLeft: 'auto'
        }} /> : <ExpandMore style={{
          marginLeft: 'auto'
        }}/> : ""
      }
      
    </Button>
  );
};

export default CustomButton;
