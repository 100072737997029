import React, {  useState } from 'react';
import { useNavigate  } from 'react-router-dom';
import CustomButton from '../CustomButton'
import '../../scss/tabs.scss'

export default function App() {
    const navigate = useNavigate();
    const [isShow,setIsShow] = useState(0)

    const clickHandler = (res)=>{
        setIsShow(res)
        if(res ===5){
          navigate('/join');
        }
    }
    const leaveHandler = ()=>{
        setIsShow(0)
    }

    const routeHandler = (id) => {
        if(id === 1){
            navigate('/datasit');
          }else if(id === 2){
            navigate('/screen');
          }else if(id === 3){
            navigate('/sheep');
          }else if(id === 4){
            navigate('/design');
          }else if(id === 5){
            navigate('/fire');
          }else if(id === 6){
            navigate('/sheefarm');
          }else if(id === 7){
            navigate('/designfarm');
          }else if(id === 8){
            navigate('/firefarm');
          }
    }

    const rouetHandler = () => {
      navigate('/contact');
    }
  return (
    <div className='header-tabs'>
        <div className='header-tab' onMouseEnter={()=>clickHandler(1)} onMouseLeave={()=>leaveHandler()}>
          <CustomButton href="#products" text="产品服务" />
          <div className={isShow === 1 ? 'header-tab-position':'header-tab-position disply-none'}>
          <div className='header-tab-position-image cusor-point'  onClick={()=>routeHandler(1)}>
                <img src='/children/header1.png' alt='1'/>
                <p  className='yfjs-text-bold'>低代码开发平台</p>
            </div>
            <div className='header-tab-position-image cusor-point'  onClick={()=>routeHandler(2)}>
                <img src='/children/header2.png' alt='1'/>
                <p  className='yfjs-text-bold'>展示大屏速搭平台</p>
            </div>
          </div>
        </div>
        <div className='header-tab' onMouseEnter={()=>clickHandler(2)} onMouseLeave={()=>leaveHandler()}>
          <CustomButton href="#solutions" text="解决方案" />
          <div className={isShow === 2 ? 'header-tab-position':'header-tab-position disply-none'}>
            <div className='header-tab-position-image cusor-point'  onClick={()=>routeHandler(3)}>
                <img src='/children/header7.png' alt='1'/>
                <p  className='yfjs-text-bold'>智慧畜牧管理解决方案</p>
            </div>
            <div className='header-tab-position-image cusor-point'  onClick={()=>routeHandler(4)}>
                <img src='/children/header9.png' alt='1'/>
                <p  className='yfjs-text-bold'>数字化线束设计解决方案</p>
            </div>
            <div className='header-tab-position-image cusor-point'  onClick={()=>routeHandler(5)}>
                <img src='/children/header8.png' alt='1'/>
                <p  className='yfjs-text-bold'>智慧消防一体化解决方案</p>
            </div>
            
            
          </div>
        </div>
        <div className='header-tab' onMouseEnter={()=>clickHandler(3)} onMouseLeave={()=>leaveHandler()}>
        <CustomButton href="#customers" text="客户案例" />
        <div className={isShow === 3 ? 'header-tab-position':'header-tab-position disply-none'}>
            <div className='header-tab-position-image cusor-point'  onClick={()=>routeHandler(6)}>
                <img src='/children/header4.png' alt='1'/>
                <p  className='yfjs-text-bold'>新疆某牧业有限责任公司</p>
            </div>
            <div className='header-tab-position-image cusor-point'  onClick={()=>routeHandler(7)}>
                <img src='/children/header5.png' alt='1'/>
                <p  className='yfjs-text-bold'>某空天行业设计研究院</p>
            </div>
            <div className='header-tab-position-image cusor-point'  onClick={()=>routeHandler(8)}>
                <img src='/children/header6.png' alt='1'/>
                <p  className='yfjs-text-bold'>应急管理部某消防研究所</p>
            </div>
          </div>
        </div>
        <div className='header-tab'  onClick={()=>rouetHandler()}>
          <CustomButton href="#about" text="关于我们" />
        </div>
        <div className='header-tab' onClick={()=>clickHandler(5)}>
            <CustomButton  text="人才招聘" />
        </div>
    </div>
  );
}