
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import CustomButton from '../components/CustomButton';
import List from '@mui/material/List';
import { ListItem } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface SidebarProps {
  onClose: () => void;
  open: boolean;
}

const Sidebar = ({ open, onClose }: SidebarProps): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [colIndex,setColIndex] = useState(-1);
  const routeHandler = (id:number) => {
    if(id === 1){
        navigate('/datasit');
      }else if(id === 2){
        navigate('/screen');
      }else if(id === 3){
        navigate('/sheep');
      }else if(id === 4){
        navigate('/design');
      }else if(id === 5){
        navigate('/fire');
      }else if(id === 6){
        navigate('/sheefarm');
      }else if(id === 7){
        navigate('/designfarm');
      }else if(id === 8){
        navigate('/firefarm');
      }
}
const rouetHandler = () => {
  navigate('/contact');
}

const clickHandler = (res:number)=>{
  if(res ===5){
    navigate('/join');
  }
}
  return (
    <>
      <Drawer
        anchor='right'
        onClose={() => onClose()}
        open={open}
        variant='temporary'
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.default,
            width: 256,
          },
        }}
      >
        
        <List>
          <ListItem>
            <CustomButton href='#products' text='产品服务' hasopen open={colIndex === 0} onClick={ () => {
                colIndex === 0  ? setColIndex(-1) : setColIndex(0)
            }}/>
          </ListItem>
          <Collapse in={colIndex === 0} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem sx={{ pl: 4 } }>
                    <CustomButton href='#products' text='低代码平台' onClick={() => routeHandler(1)}/>
                  </ListItem>
                  <ListItem sx={{ pl: 4 } }>
                    <CustomButton href='#products' text='展示大屏' onClick={() => routeHandler(2)}/>
                  </ListItem>
                </List>
          </Collapse>
          <ListItem>
            <CustomButton href='#products' text='解决方案' hasopen open={colIndex === 1} onClick={ () => {
                colIndex === 1 ? setColIndex(-1) : setColIndex(1)
            }}/>
          </ListItem>
          <Collapse in={colIndex === 1} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem sx={{ pl: 4 } }>
                    <CustomButton href='#products' text='智慧畜牧' onClick={() => routeHandler(3)}/>
                  </ListItem>
                  <ListItem sx={{ pl: 4 } }>
                    <CustomButton href='#products' text='HT设计' onClick={() => routeHandler(4)}/>
                  </ListItem>
                  <ListItem sx={{ pl: 4 } }>
                    <CustomButton href='#products' text='消防' onClick={() => routeHandler(5)}/>
                  </ListItem>
                </List>
          </Collapse>
          
          <ListItem>
            <CustomButton href='#products' text='客户案例' hasopen open={colIndex === 2} onClick={ () => {
                colIndex === 2 ? setColIndex(-1) : setColIndex(2)
            }}/>
          </ListItem>
          <Collapse in={colIndex === 2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem sx={{ pl: 4 } }>
                    <CustomButton href='#products' text='新疆信诚牧业集团' onClick={() => routeHandler(6)}/>
                  </ListItem>
                  <ListItem sx={{ pl: 4 } }>
                    <CustomButton href='#products' text='HT设计研究院' onClick={() => routeHandler(7)}/>
                  </ListItem>
                  <ListItem sx={{ pl: 4 } }>
                    <CustomButton href='#products' text='四川消防研究所' onClick={() => routeHandler(8)}/>
                  </ListItem>
                </List>
          </Collapse>
        
          <ListItem>
            <CustomButton href='#products' text='关于我们' onClick={()=>rouetHandler()}/>
          </ListItem>
        
          <ListItem>
            <CustomButton href='#products' text='联系我们' onClick={()=>clickHandler(5)}/>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default Sidebar;
