import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate  } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTheme } from '@mui/material/styles';
import api from '../tests/apiurl'
import '../scss/customer.scss'
const Customers = (): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [customers, setCustomers] = useState([]);

  const fetchCustomers = () => {
    axios
      .get(api.baseurl+'/api/www/customer/case/getList', {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setCustomers(response.data.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const customerHandler = (id:any) => {
    if(id === 0){
      navigate('/sheep');
    }else if(id === 1){
      navigate('/fire');
    }else if(id === 2){
      navigate('/design');
    }

  }

  return (
    <div id='customers'>
      <Box
        sx={{
          paddingTop: 5,
          paddingBottom: 10,
          paddingX: 2,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Box marginBottom={4}>
          <Typography
            variant='h5'
            align='center'
            fontWeight={700}
            marginTop={theme.spacing(1)}
            gutterBottom
            sx={{
              color: theme.palette.text.primary,
              textTransform: 'uppercase',
            }}
          >
            客户案例
          </Typography>
          <Typography
            variant='subtitle1'
            align='center'
            marginTop={theme.spacing(1)}
            gutterBottom
            color={theme.palette.text.secondary}
          >
           实践验证 | 效果显著 | 用户至上 | 共创价值
          </Typography>
        </Box>
        <Container className='customer-display'>
          <Grid  className='customer-card-center'>
            {customers.map((item:any, i) => (
              <div className='customer-card cusor-point' key={i} onClick={()=>customerHandler(i)}>
                <img src={api.imgurl+item.imageObj.url} alt={'customer'+(i+1)+'.png'}/>
                <div className='customer-card-contain'>
                  <p className='contain-title'>
                  {item.title}
                  </p>
                  <div className={`contain-title-border contain-title${i}`}/>
                  {
                  item.featureList?.map((feature:any, j:number) => (
                    <div className='contain-text' key={j}>
                      <span>{feature}</span>
                    </div>
                      ))
                      }
                  <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',marginTop:'20px',fontSize:'12px',color:'#DFDFDF'}}>查看更多<ArrowForwardIcon style={{ marginRight:'10px',fontSize:'14px',color:'#DFDFDF' }}/></div>
                </div>
              </div>
              // <Grid item xs={12} md={4} key={i}>
              //   <Box
              //     component={Card}
              //     height={1}
              //     display='flex'
              //     flexDirection='column'
              //     boxShadow={0}
              //     border={`1px solid ${theme.palette.divider}`}
              //   >
              //     <CardContent
              //       sx={{
              //         padding: { sm: 4 },
              //       }}
              //     >
              //       <Box
              //         marginBottom={4}
              //         display='flex'
              //         flexDirection='column'
              //         alignItems='center'
              //       >
              //         <Typography variant='h6' gutterBottom>
              //           <Box component='span' fontWeight={600}>
              //             {item.title}
              //           </Box>
              //         </Typography>
              //         <Box display='flex' alignItems='flex-start'>
              //           <Typography variant='h4' color='primary'>
              //             <Box
              //               component='span'
              //               fontWeight={600}
              //               marginRight={1 / 2}
              //             >
              //               {item.currency}
              //             </Box>
              //           </Typography>
              //           <Typography variant='h2' color='primary' gutterBottom>
              //             <Box component='span' fontWeight={600}>
              //               {item.price}
              //             </Box>
              //           </Typography>
              //         </Box>
              //         <Typography
              //           variant='subtitle2'
              //           color={theme.palette.text.secondary}
              //         >
              //           客户实现的功能：
              //         </Typography>
              //       </Box>
              //       <Grid container spacing={1}>
              //         {item.features?.map((feature, j) => (
              //           <Grid item xs={12} key={j}>
              //             <Typography
              //               component='p'
              //               align='center'
              //               sx={{
              //                 textDecoration: 'none',
              //               }}
              //             >
              //               {feature.name}
              //             </Typography>
              //           </Grid>
              //         ))}
              //       </Grid>
              //     </CardContent>
              //     <Box flexGrow={1} />
              //     <CardActions sx={{ justifyContent: 'center', padding: 4 }}>
              //       <Button
              //         size='large'
              //         variant='contained'
              //         endIcon={<ArrowForwardIcon />}
              //         sx={{
              //           textTransform: 'uppercase',
              //           color: theme.palette.common.black,
              //           border: `2px solid ${theme.palette.primary.main}`,
              //           '&:hover': {
              //             backgroundColor: 'transparent',
              //             color: theme.palette.primary.main,
              //             border: `2px solid ${theme.palette.primary.main}`,
              //           },
              //         }}
              //       >
              //         Get started
              //       </Button>
              //     </CardActions>
              //   </Box>
              // </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default Customers;
