import { useState, useEffect } from 'react';
import { useNavigate  } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import api from '../tests/apiurl'
import '../scss/product.scss'
const Products = (): JSX.Element => {
  const theme = useTheme();

  const [products, setProducts] = useState([]);
  const navigate = useNavigate();


  const fetchProducts = () => {
    axios
      .get(api.baseurl+'/api/www/project/getList', {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if(response.data){
          setProducts(response.data.data);
        }
      })
      .catch((error) => console.log(error));
  };
  const routerClick = (id:any) => {
    if(id === 0){
      navigate('/datasit');
    }else if(id === 1){
      navigate('/screen');
    }

  }

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div id='products'>
      <Box
        sx={{
          paddingTop: 5,
          paddingBottom: 10,
          paddingX: 2,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Box marginBottom={4}>
          <Typography
            variant='h5'
            align='center'
            fontWeight={700}
            marginTop={theme.spacing(1)}
            gutterBottom
            sx={{
              color: theme.palette.text.primary,
              textTransform: 'uppercase',
            }}
          >
            产品服务
          </Typography>
          <Typography
            variant='subtitle1'
            align='center'
            marginTop={theme.spacing(1)}
            gutterBottom
            color={theme.palette.text.secondary}
          >
            业务导向 | 科技赋能 | 智能核心 | 数据驱动
          </Typography>
        </Box>
        <Container className='gird-container'>
          <Grid container spacing={4}>
            {products.map(( item : any, i) => {
              return (
              <Grid item xs={12} sm={6} key={i} >
                <Box
                  className={`cusor-point product-card-border border${i}`}
                  onClick={()=>routerClick(i)}
                  component={Card}
                  padding={4}
                  width={1}
                  height={1}
                  bgcolor={theme.palette.background.paper}
                  sx={{
                    '&:hover': {
                      bgcolor: theme.palette.background.default,
                      color: theme.palette.common.white,
                    },
                  }}
                >
                  <Box display='flex' width={1} height={1}  className='product-card-out'>
                    <Grid container alignItems={'center'} justifyContent={'center'}>
                      <Grid item md={4} sm={12} alignItems={'center'} justifyContent={'center'}>
                        <div className='product-card'>
                          <div className={i === 0?'bac-class':'bac-class-two'}>
                            <img src={item.iconObj?(api.imgurl+item.iconObj.url):''} alt='png'/>
                          </div>
                          <p className='first-p'>{ item.name }</p>
                          <p className='yfjs-text-white'>{ item.description}</p>
                        </div>
                      </Grid>
                      <Grid item md={8} sm={12}>
                        <div className='product-card-image'>
                          <img src={item.iconObj?(api.imgurl+item.imageObj.url):''} alt='1111'/>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            )})}
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default Products;
