import { useState, useRef } from 'react';
import { TextField, InputAdornment, IconButton, Typography, Snackbar, Alert } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';
import axios from 'axios';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import api from '../../tests/apiurl'
export default function MyForm({ handleCloseHandler }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [fileObj, setFileObj] = useState(null);
    const [open, setOpen] = useState(0); // 控制 Snackbar 的显示
    const [message, setMessage] = useState(''); // Snackbar 显示的消息
    const [submitType, setSubmitType] = useState('success')
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const fileType = ['pdf', 'word', 'docx']
        if (!file) return;
        // 限制文件大小为500MB
        if (file.size > 500 * 1024 * 1024) {
            alert('文件大小不能超过500MB');
            return;
        }
        if (file.type !== "" || file.type !== null || file.type !== undefined) {
            // 截取文件的后缀，判断文件类型
            const FileExt = file.name.replace(/.+\./, "").toLowerCase();
            // 计算文件的大小
            const isLt2M = file.size / 1024 / 1024 < 2; // 这里做文件大小限制，2MB
            // 如果大于2MB
            if (!isLt2M) {
                setSubmitType('error')
                setMessage('上传文件大小不能超过 2MB!"'); // 设置失败消息
                setOpen(true); // 显示 Snackbarrror("上传文件大小不能超过 500MB!");
                // return false;
            } else if (!fileType.includes(FileExt)) {
                setSubmitType('error')
                setMessage('上传文件格式不正确'); // 设置失败消息
                setOpen(true); // 显示 Snackbar
            } else {
                setSubmitType('success')
                setMessage('上传成功！'); // 设置成功消息
                setOpen(true); // 显示 Snackbar
                setFileObj(file);
                // return true;
            }
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (!fileObj) {
            setSubmitType('error')
            setMessage('请上传简历'); // 设置失败消息
            setOpen(true); // 显示 Snackbar
            return
        }
        const formDatas = new FormData();
        // 在这里处理表单提交逻辑，例如发送到服务器等
        const obj = { name, email, phone, file: fileObj, "companyName": "上海有限公司", }
        for (let key in obj) {
            formDatas.append(key, obj[key]);
        }
        axios
            .post(api.baseurl + '/api/www/resume/add', formDatas, {
                headers: {
                    Accept: 'application/json',
                },
            })
            .then((response) => {
                if (response.data.code === 200) {
                    setSubmitType('success')
                    setMessage('申请成功！'); // 设置成功消息
                    setOpen(true); // 显示 Snackbar
                    setTimeout(() => {
                        handleCloseHandler()

                    }, 500)


                } else {
                    setSubmitType('error')
                    setMessage(response.data.msg); // 设置成功消息
                    setOpen(true); // 显示 Snackbar
                }

            })
            .catch((error) => console.log(error));
    };




    // 
    const handleClose = () => {
        setOpen(false); // 关闭 Snackbar
    };


    const handleButtonClick = () => {
        fileInputRef.current.click();
    };
    const StyledButton = styled(Button)({
        marginTop: '20px',
        marginLeft: '10px',
        backgroundColor: '#2196f3',
        color: 'white',
        padding: '10px 40px',
        '&:hover': {
            backgroundColor: '#1976d2',
        },
    });

    const CancelButton = styled(Button)({
        marginTop: '20px',
        marginLeft: '10px',
        backgroundColor: 'white',
        color: 'black',
        padding: '10px 40px',
        '&:hover': {
            backgroundColor: '#ccc',
        },
    });

    const validateEmail = (value) => {
        // 邮箱验证规则
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(value) && value.endsWith('.com');
        setEmailError(isValid ? '' : '请输入有效的 .com 邮箱');
    };

    const validatePhone = (value) => {
        // 手机号验证规则
        const phoneRegex = /^1\d{10}$/;
        const isValid = phoneRegex.test(value);
        setPhoneError(isValid ? '' : '请输入以 1 开头的 11 位手机号码');
    };


    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        label="姓名"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        variant="outlined"
                        InputLabelProps={{ style: { color: 'black' } }}
                        inputProps={{ style: { color: 'black' } }}
                    />
                </Grid>
                <Grid item xs={6}>
                <TextField
                    label="邮箱"
                    type="email"
                    value={email}
                    onChange={(e) => {
                        const value = e.target.value;
                        setEmail(value);
                        validateEmail(value);
                    }}
                    required
                    variant="outlined"
                    InputLabelProps={{ style: { color: 'black' } }}
                    inputProps={{ style: { color: 'black' } }}
                    error={!!emailError}
                    helperText={emailError}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="电话"
                    type="text" // 修改为 "text" 类型以便自定义验证
                    value={phone}
                    onChange={(e) => {
                        const value = e.target.value;
                        setPhone(value);
                        validatePhone(value);
                    }}
                    required
                    variant="outlined"
                    InputLabelProps={{ style: { color: 'black' } }}
                    inputProps={{ style: { color: 'black' } }}
                    error={!!phoneError}
                    helperText={phoneError}
                />
            </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        type="file"
                        onChange={handleFileChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" style={{ marginTop: '25px', height: 'auto' }}>
                                    <IconButton
                                        onClick={handleButtonClick}
                                        size="large"
                                        style={{
                                            padding: 0, '&:hover': {
                                                backgroundColor: 'transparent', // 去掉鼠标悬浮时的背景色
                                            }
                                        }}
                                    >
                                        <CloudUploadIcon style={{ color: '#2196f3' }} />
                                    </IconButton>
                                    <Typography
                                        variant="body1"
                                        style={{
                                            marginLeft: '8px',
                                            fontSize: '16px',
                                            color: '#000',
                                        }}
                                    >
                                        {
                                            fileObj ? `已上传：${fileObj.name}` : '上传文件(限制pdf或者word)'
                                        }

                                    </Typography>
                                </InputAdornment>
                            ),
                            inputProps: {
                                ref: fileInputRef,
                                style: { display: 'none' },
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: { fontSize: 16, color: '#666' },
                        }}
                    />

                </Grid>
            </Grid>
            <StyledButton
                type="submit"
                variant="contained"
            >
                发送
            </StyledButton>
            <CancelButton
                type="button"
                variant="outlined"
                onClick={handleCloseHandler}
            >
                取消
            </CancelButton>

            <Snackbar
                open={open}
                autoHideDuration={6000} // 6秒后自动关闭
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{
                    position: 'fixed',
                    bottom: '50%',
                    left: '50%',
                    transform: 'translate(-50%, 50%)',
                    zIndex: 1300, // 确保在其他内容之上
                }}
            >
                <Alert onClose={handleClose} severity={submitType}>
                    {message}
                </Alert>
            </Snackbar>

        </form>
    );
};
