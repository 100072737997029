import { useState, useEffect } from 'react';
import axios from 'axios';
// import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import MySwiperComponent from './com/swiper2'; 
import api from '../tests/apiurl'
import '../scss/solution.scss'

const Solutions = (): JSX.Element => {
  const theme = useTheme();

  const [solutions, setSolutions] = useState([]);


  const fetchSolutions = () => {
    axios
      .get(api.baseurl+'/api/www/solution/getList', {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setSolutions(response.data.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchSolutions();
  }, []);

  return (
    <div id='solutions'>
      <Box
        sx={{
          paddingTop: 5,
          paddingBottom: 10,
          paddingX: 2,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Box marginBottom={4}>
          <Typography
            variant='h5'
            align='center'
            fontWeight={700}
            marginTop={theme.spacing(1)}
            gutterBottom
            sx={{
              color: theme.palette.text.primary,
              textTransform: 'uppercase',
            }}
          >
            解决方案
          </Typography>
          <Typography
            variant='subtitle1'
            align='center'
            marginTop={theme.spacing(1)}
            gutterBottom
            color={theme.palette.text.secondary}
          >
           行业定制 | 场景落地 | 痛点解决 | 价值提升
          </Typography>
        </Box>
        <Container className='solutin-class'>
          <MySwiperComponent solutions={solutions} api={api}/>
        </Container>
      </Box>
    </div>
  );
};

export default Solutions;
