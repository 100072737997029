
import { useState } from 'react';
import Swiper from './swiper';
import './contact.scss'
import { Grid } from '@mui/material';
const About = () => {
  const contact = window.config.contact || {}
  const [buttonId, setButtonId] = useState(2)
  const mouseEnter = (res) => {
    setButtonId(res)
  }
  const mouseLeave = () => {
    setButtonId(0)
  }
  return (
    <div id='contact-page'>
      <div className='contact-page-image'>
        <img src='/children/contact3.png' alt='contact-home' className='contact1' />
        <img src='/children/contact4.png' alt='contact-position' className='contact2' />
        <div className='contact-title'>
          <p>{contact.title}</p>
          <span>{contact.subTitle}</span>
        </div>

      </div>
      <div className='contact-page-show-out'>
        <div className='contact-page-show'>
          {
            contact&&contact.program.map((i,index)=>{
              return  <p className='yfjs-text-white'  key={index}>{i}</p>
            })
          }
        </div>
        <div className='contact-page-show-out-image'>
          <img src='/children/contact2.png' alt='contact-home' className='contact2' />
        </div>

      </div>


      <div className='contact-page-introduce'>
        <div className='introduce-left'>
          <div  className='introduce-left-image'>
            <img src={contact.intro.img||'/children/contact5.png'} alt='contact-home' className='contact5' />
            <div className='introduce-left-position'>
              <p>
                姓名：{contact.intro.name}
              </p>
              <p>
                职位：{contact.intro.job}
              </p>
            </div>
          </div>
        </div>
        <div className='introduce-right'>
          <div className='introduce-right-top'>
            {
              contact.intro.content.map((i,index)=>{
                return <div className='introduce-item'  key={index}>
                <p style={{ color: i.color || '#bda7ff' }}>{i.title}</p>
                <span>{i.text}</span>
              </div>
              })
            }
          </div>
          <div className='introduce-right-bottom'>
          {contact.intro.introduce}
          </div>

        </div>

      </div>


      <div className='contact-page-bottom'>
        <Grid container spacing={2}>
            {
              contact.advantage&&contact.advantage.map((i,index)=>{
                if(index === 0){
                  return <Grid item md={4} xs={12}  key={index}>
                    <div className='contact-page-bottom-first'>
                      <p>{i.title}</p>
                      <p>{i.text}</p>
                    </div>
                  </Grid>
                }else{
                  return <Grid item md={4} xs={12} key={index}>
                  <div className={buttonId === index ? 'contact-page-bottom-blue' : 'contact-page-bottom-item contact-item'} onMouseEnter={() => mouseEnter(index)} onMouseLeave={() => mouseLeave()}>
                    <div className='contact-bottom-title'>
                    {index}
                    </div>
                    <p>{i.title}</p>
                    <p>{i.text}
                    </p>
                  </div>
                </Grid>

                }
              })
            }
        </Grid>
      </div>

      
      <div className='contact-page-swiper-bottom'>
        <p className='yfjs-title-white'>{contact.company.title}</p>
        <Swiper images={contact.company.img}/>
      </div>

    </div>
  );
};

export default About;
