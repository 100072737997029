import { Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './sheep.scss'
const About = () => {
  const sheep = window.config.sheep || {}
  const navigate = useNavigate();
  const routerHandler = () => {
    navigate('/contactus');
  }

  return (
    <div id='sheep'>
      <div className='sheep-image'>
        <img src='/screen/homesheep.png' alt='screnn-home' />
        <Box className='sheep-image-position'
        >
          <p className='sheep-image-position-p'>{sheep.title}</p>
          <div className='sheep-image-padding'>
            {sheep.subTitle}
          </div>
        </Box>
      </div>
      <div className='sheep-show-top'>{sheep.custom.title}</div>
      <div className='sheep-show'>
        <Grid container>
          <Grid item xs={12} md={5}>
            <div className='sheep-show-left'>
              <img src={sheep.custom.img || '/children/sheep1.png'} alt='sheep1' />
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <div className='sheep-show-right'>
              <p>{sheep.custom.subTitle}</p>
              <span>
                {sheep.custom.text}
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className='sheep-show-bottom'>
        <div className='sheep-show-bottom-title'>
          <span>{sheep.contentTitle}</span>
        </div>


        <Box className='sheep-show-bottom-content'
          sx={{
            padding: {
              md: "10px 60px",
              xs: "10px 20px"
            }
          }}
        >
          <Grid container spacing={20}  justifyContent={'space-around'}>
            {
              sheep.content.map((i, index) => {
                return <Grid item xs={12} md={4} key={index}>
                  <div className='sheep-show-bottom-content-item'>
                    <img src={i.img} alt={'sheep' + index} />
                    <p>{i.title}</p>
                    <span>
                      {i.text}
                    </span>
                  </div>

                </Grid>
              })
            }

          </Grid>
        </Box>
        <Box className='sheep-show-bottom-button cusor-point'
          sx={
            {
              width: {
                xs: '300px !important',
              }
            }
          }
          onClick={() => { routerHandler() }}
        >
          申请体验
        </Box>
      </div>
      <div className='sheep-show-footer'>
        <Box className='image1 image-position'>
          <img src='/children/sheep2.png' alt='sheep2' />
        </Box>
        <div className='image2  image-position'>
          <img src='/children/sheep3.png' alt='sheep3' />
        </div>
        <div className='image3  image-position'>
          <img src='/children/sheep4.png' alt='sheep4' />
        </div>

      </div>

    </div>

  );
};

export default About;
