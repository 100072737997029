import React, { useState } from 'react';
import '../../scss/sider.scss'

export default function App() {
    const [now,setNow] = useState('')

    const clickHandler = (name) => {
        console.log(name,'namee')
        setNow(name)
    }

    const scrolToHandler = (id)=> {
        setTimeout(() => {
            const element = document.querySelector(`#${id}`);
            if (!element) {
              return;
            }
            window.scrollTo({ left: 0, top: element.offsetTop, behavior: 'smooth' });
          });

    }
  return (
    <>
     <div className='sider-position' id='top-scroll'>
        <div className={now === 'wei'?'sider-position-boder back-color cusor-point':'sider-position-boder cusor-point'} >
            <img src={now === 'wei'?'/sider-wei1.png':'/sider-wei.png'} alt='sider-wei' onMouseEnter={()=>clickHandler('wei')} onMouseLeave={()=>setNow('')}/>
            {
                now === 'wei'&& <div className='contact-position'>
                <p>扫描关注</p>
                <img src='/contact.png' alt='contact.png'/>
            </div>
            }
        </div>
        <div className={now === 'phone'?'sider-position-boder cusor-point back-color':'sider-position-boder cusor-point'} >
            <img src={now === 'phone'?'/sider-phone1.png':'/sider-phone.png'} alt='sider-phone'  onMouseEnter={()=>clickHandler('phone')} onMouseLeave={()=>setNow('')}/>
            {
                now === 'phone'&& <div className='contact-position-two'>
                <p>电话号码</p>
                <p>177-1705-0588</p>
            </div>
            }
        </div>
        <div className={now === 'arrow'?'sider-position-boder cusor-point back-color':'sider-position-boder cusor-point'} >
            <img src={now === 'arrow'?'/sider-arrow1.png':'/sider-arrow.png'} alt='sider-arrow'  onClick={()=>scrolToHandler('header')}  onMouseEnter={()=>clickHandler('arrow')} onMouseLeave={()=>setNow('')}/>
        </div>
     </div>
    </>
  );
}
