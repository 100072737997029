import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Form from './Form'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '50%',
    color: 'black',
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
};

export default function BasicModal({ open, setOpen, modal={} }) {
    const handleClose = () => setOpen(-1);
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{overflowY:'scroll'}}
            >
                <Box sx={{...style,marginTop:'200px'}} className='join-modal-class'>
                    <h2>{modal.mainTitle}</h2>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {modal.mainText}
                        <h5>{modal.positionTitle}</h5>
                        <ol>
                            {
                                modal.positionText&&modal.positionText.map((item)=>{
                                          return <li>{item}</li>
                                })
                            }
                        </ol>
                        <h5>{modal.jobTitle}</h5>
                        <ol>
                            {
                                modal.jobText&&modal.jobText.map((item)=>{
                                          return <li>{item}</li>
                                })
                            }
                        </ol>
                    </Typography>

                    <h2>现在申请:</h2>
                    <Form handleCloseHandler={handleClose}/>
                </Box>
            </Modal>
        </div>
    );
}