import { useState } from 'react';
import '../../scss/children.scss'
import { Grid } from '@mui/material';
import { useNavigate  } from 'react-router-dom';
import Swiper from '../screen/swiper';
const About = () => {
  const datasit = window.config.datasit || {}
  const [buttonId, setButtonId] = useState(0)
  const navigate = useNavigate();
  const routerHandler = () => {
    navigate('/contactus');
  }
  const clickHandler = (res) => {
    setButtonId(res)
  }
  return (
    <div id='screen'>
      <div className='screen-image'>
        <img src='/screen/homedata.png' alt='screnn-home' />
        <div  className='screen-image-position'>
          <p  className='screen-image-position-p'>{datasit.title}</p>
          <p  className='screen-image-position-text'>{datasit.subTitle}</p>
          <div  className='screen-image-position-button cusor-point' onClick={()=>{routerHandler()}}>
            申请体验
          </div>
        </div>
      </div>
      <div className='screnn-show'>
        <div className='screnn-show-top'>
          <Grid container>
              {
                datasit.button&&datasit.button.map((item, index) => {
                  return <Grid item key={index}>
                      <div className={item.id === buttonId ? 'screen-show-button button-arrow cusor-point' : 'screen-show-button cusor-point'} onClick={() => clickHandler(index)} key={index}>
                      {item.name}
                    </div>
                  </Grid>
                })
              }
          </Grid>
        </div>
        {
         datasit.content&&datasit.content[buttonId] && <div className='screnn-show-bot'>
            <Grid container>
                <Grid item md={5}>
                <div className='screnn-show-bot-left'>
              <div className='screnntop'>
                <img src={datasit.content[buttonId].icon} alt='screnn-vector' />
              </div>
              <div className='scrennbottom'>
                <div className='yfjs-title-white'>{datasit.content[buttonId].title}</div>
                <div className='yfjs-text-white'>
                  {datasit.content[buttonId].option}
                </div>
              </div>
            </div> 
                </Grid>
                <Grid item md={7}>
                <div className='screnn-show-bot-right'  key={buttonId}>
                  <div  className='screnn-show-bot-right-border'>
                    <video width="100%" height="auto" loop autoPlay muted>
                        <source src={datasit.content[buttonId].img}  type="video/mp4" />
                    </video>
                  </div>
                </div>
                </Grid>
              </Grid>
          </div>
        }

      </div>

      <div  className='screnn-swiper'>
        <div className='font-title'>
          {datasit.swiperTitle}
        </div>
        <div  className='screnn-swiper-content'>
          <Swiper images={datasit.swiper}/>
        </div>
      </div>

    </div>
  );
};

export default About;
