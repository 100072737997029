import { Box } from "@mui/material";
import Form from "./Form";
import './contactus.scss'
const About = () => {
  return (
    <div id='contactus-page'>
      <div className='contact-page-image'>
        <img src='/children/contact1.png' alt='contact-home'  className='contact1'/>
        <div className='contactus-home-position'>
          <div className='yfjs-title-white'>感兴趣？ 联系我们获取更多信息</div>
          <Box className='contactus-button'>电话咨询<span className='yfjs-text-white'>177-1705-0588</span></Box>
        </div>
      </div>
      <div className='contact-page-show'>
        <p className='yfjs-title-white-16-p'>立即获取您的数字化专属方案</p>
        <Form/>
      </div>
    </div>
  );
};

export default About;
