import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import getTheme from './theme/theme';
import Layout from './layout/Layout';
import Home from './pages/Home';
// 大屏子页面
import Screen from './pages/screen/Screen';
// 低代码页面
import Datasit from './pages/datasit/Datasit';
import Sheep from './pages/sheep/Sheep';
import Design from './pages/design/Design';
import DesignFarm from './pages/designfarm/DesignFarm';
import Fire from './pages/fire/Fire';
import FireFarm from './pages/fireFarm/FireFarm';
import Contact from './pages/contact/Contact';
import Join from './pages/join/Join';
import ContactUs from "./pages/contactUs/ContactUs";
import SheepFarm from "./pages/sheepFarm/SheepFarm";


import ContactFooter from './components/ContactFooter';
import Sider from './components/com/sider';
import './scss/index.scss'
const App = (): JSX.Element => {
  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | 上海翼方键数科技有限公司"
        defaultTitle="上海翼方键数科技有限公司"
      />
      <ThemeProvider theme={getTheme()}>
        <CssBaseline />
        <BrowserRouter>
          <Layout>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/screen' element={<Screen />} />
              <Route path='/datasit' element={<Datasit />} />
              <Route path='/design' element={<Design />} />
              <Route path='/designfarm' element={<DesignFarm />} />
              <Route path='/sheep' element={<Sheep />} />
              <Route path='/fire' element={<Fire />} />
              <Route path='/firefarm' element={<FireFarm />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/join' element={<Join />} />
              <Route path='/contactus' element={<ContactUs />} />
              <Route path='/sheefarm' element={<SheepFarm />} />
            </Routes>
            <ContactFooter />
            <Sider/>
          </Layout>
        </BrowserRouter>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
