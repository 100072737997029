import React, { useRef} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate  } from 'react-router-dom';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation,Scrollbar,Autoplay } from 'swiper/modules';
import { Box } from '@mui/material';

export default function App({ solutions,api }) {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  const navigate = useNavigate();
  const routeHandler = (id) => {
    if(id === 0){
      navigate('/sheep');
    }else if(id === 1){
      navigate('/design');
    }else if(id === 2){
      navigate('/fire');
    }

  }

  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        // autoplay={{
        //   delay: 2500,
        //   disableOnInteraction: false,
        // }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Navigation, Pagination, Scrollbar, Autoplay]} // 添加 Autoplay 模块
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        className="mySwiper"
      >
        {
           solutions.map((item, i) => (
            <SwiperSlide key={i} className='yfjs-slider-image cusor-point' onClick={()=>routeHandler(i)}> 
              <img src={api.imgurl+item.imageObj.url} alt='solution' style={{
                // height: '100%',
                // height:'auto',
                // width:'100%',
                // objectFit: 'cover'
              }}/>
              <Box className='solution-position' sx={{
                padding: {
                   xs: "10px 10px !important",
                }
              }}>
                    <div className='top-text'>
                    {item.title}
                    </div>
                    <div  className='bottom-text'>
                    {item.description}
                    </div>
              </Box>
            </SwiperSlide>
            
          ))
        }
        <div className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </div>
      </Swiper>
    </>
  );
}
