import { Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './fire.scss'
const About = () => {
  const fire = window.config.fire || {}
  const navigate = useNavigate();
  const routerHandler = () => {
    navigate('/contactus');
  }

  return (
    <div id='fire'>
      <div className='fire-image'>
        <img src='/screen/homefire.png' alt='screnn-home' />
        <Box className='fire-image-position'
        >
          <p className='fire-image-position-p'>{fire.title}</p>
          <div className='fire-image-padding'>
            {fire.subTitle}
          </div>
        </Box>
      </div>
      <div className='fire-show-top'>{fire.custom.title}</div>
      <div className='fire-show'>
        <Grid container>
          <Grid item xs={12} md={5}>
            <div className='fire-show-left'>
              <img src={fire.custom.img || '/children/fire1.png'} alt='fire1' />
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <div className='fire-show-right'>
              <p>{fire.custom.subTitle}</p>
              <span>
                {fire.custom.text}
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className='fire-show-bottom'>
        <div className='fire-show-bottom-title'>
          <span>{fire.contentTitle}</span>
        </div>


        <Box className='fire-show-bottom-content'
          sx={{
            padding: {
              md: "10px 60px",
              xs: "10px 20px"
            }
          }}
        >
          <Grid container spacing={20} justifyContent={'space-around'}>
            {
              fire.content.map((i, index) => {
                return <Grid item xs={12} md={4} key={index}>
                  <div className='fire-show-bottom-content-item'>
                    <img src={i.img} alt={'fire' + index} />
                    <p>{i.title}</p>
                    <span>
                      {i.text}
                    </span>
                  </div>

                </Grid>
              })
            }

          </Grid>
        </Box>
        <Box className='fire-show-bottom-button cusor-point'
          sx={
            {
              width: {
                xs: '300px !important',
              }
            }
          }
          onClick={() => { routerHandler() }}
        >
          申请体验
        </Box>
      </div>
      <div  className='fire-audio'>
      <div className='font-title'>
          {fire.audio&&fire.audio.title}
        </div>
        <div  className='screnn-audio-content'>
        <video controls width="100%" height="auto" loop>
            <source src={fire.audio&&fire.audio.img||"/audio/video.mp4"} type="video/mp4" />
        </video>
        </div>
      </div>

    </div>

  );
};

export default About;
